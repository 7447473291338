<template>
  <div class="my-register-page">
      <div class="my-container">
        
          <div>
              <b-row>
                  <b-col>
                      <h1 >{{ $t('ControlFunction') }}</h1>
                  </b-col>
                  <b-col class="mt-4" style="text-align:right">
           <b-button style="width:100px" @click="$router.go(-1)" variant="outline-danger"> {{ $t('back') }} </b-button>
        </b-col>
              </b-row>
                <b-row class="mt-4">
                    <b-col sm="12" md="6">
                        <custom-select @input="Refresh" :label="$t('Organization')" :clearable="true" :valueid="'value'" :valuename="'text'" :options="OrganizationList" v-model="filters.parentOrganizationId.value" />
                    </b-col>
                     <b-col sm="12" md="3">
                        <custom-input @keyup.native.enter="Refresh" v-model="filter.okedCode" :label="$t('oked')"></custom-input>
                    </b-col>
                    <b-col sm="12" md="1">
                        <b-form-checkbox @input="Refresh" id="checkbox-1" class="mt-3" v-model="filter.byOked">{{ $t('oked')}}</b-form-checkbox>
                    </b-col>
                    <b-col sm="12" md="2">
                        <custom-button block @click.native="Refresh"> <span> <b-spinner style="margin-left : 20px" v-if="SearchLoading" small></b-spinner> {{ $t('search') }} </span> </custom-button>
                    </b-col>
                    
                </b-row>
                <b-row style="margin-top:32px">
                  <b-table-simple bordered>
                    <b-thead>
                      <b-tr variant="primary">
                         <b-th style="vertical-align: middle">
                          {{ $t('ControlFunctionName') }}
                        </b-th>
                        <b-th style="vertical-align: middle">
                          {{ $t('form') }}
                        </b-th>
                        <b-th style="vertical-align: middle">
                          {{ $t('normativeLegalDoc') }}
                        </b-th>
                        <b-th style="vertical-align: middle">
                          {{ $t('oked') }}
                        </b-th>
                        <b-th style="vertical-align: middle">
                          {{ $t('parentOrganization') }}
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody v-if="Parent.length > 0 && !SearchLoading">
                      <b-tr v-for="item,index in Parent" :key="index">
                         <b-td>
                          {{ item.name }}
                        </b-td>
                        <b-td>
                          {{ item.forms.join(", ") }}
                        </b-td>
                        <b-td>
                          {{ item.normativeLegalDoc }}
                        </b-td>
                        <b-td>
                          {{ item.okeds.join(', ') }}
                        </b-td>
                        <b-td>
                          {{ item.parentOrganization }}
                        </b-td>
                      </b-tr>
                    </b-tbody>
                    <b-tbody v-if="SearchLoading">
                      <b-tr>
                        <b-td colspan="4" class="text-center">
                          <b-spinner style="margin-left : 20px" v-if="SearchLoading" small></b-spinner> {{ $t('Loading') }}...
                        </b-td>
                      </b-tr>
                    </b-tbody>
                    <b-tbody v-if="Parent.length == 0 && !SearchLoading">
                      <b-tr>
                        <b-td colspan="4" class="text-center">
                           {{ $t('NoItems') }}
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-row>
          </div>
      </div>
  </div>
</template>

<script>
import { BRow,BCol,BImg,BSpinner, BSidebar,BFormCheckbox,BIconPencil,BIconCheck2Square,BFormFile,BIconPlus,BIconX, BTable, BTableSimple, BTbody, BThead, BTr, BTh, BTd} from 'bootstrap-vue'
import customInput from '../../components/elements/customInput.vue'
import customDatePicker from '../../components/elements/customDatePicker.vue'
import customSelect from '../../components/elements/customSelect.vue'
import customButtonOutline from '../../components/elements/customButtonOutline.vue'
import customButton from '../../components/elements/customButton.vue'
import customRadioButton from '../../components/elements/customRadioButton.vue'
import customBadge from '../../components/elements/customBadge.vue'
import customDialog from '../../components/elements/customDiaolg.vue'
import AccountService from '@/services/account.service'
import InspectorService from "@/services/inspector.service"
import customLabel from '../../components/elements/customLabel.vue'
import PlusIcon from '../../components/custom-icons/PlusIcon.vue'
import ManualService from '@/services/manual.service'
import ControlFunctionService from '@/services/controlfunction.service'
import vSelect from 'vue-select'
export default {
    components : {  
        BRow,
        BCol,
        BImg,
        customInput,
        customDatePicker,
        customSelect,
        BIconX,
        customButtonOutline,
        BSpinner,
        customButton,PlusIcon,customRadioButton,vSelect,customDialog,customLabel, BSidebar, BTableSimple, BTbody, BThead, BTr, BTh, BTd, BFormCheckbox ,BIconPencil,BIconCheck2Square,BFormFile,BIconPlus,customBadge,BTable },
    data(){
        return{
            SearchLoading : false,
            Parent : {},
            SaveLoading : false,
            lang : '',
            sidebar1 : false,
            OrganizationList : [],
            filter : {
              byOked : false,
              okedCode:"",
              parentId : '',
              authorizedOnly : false,
              inspectionOnly : false,
            },
            filters : {
              parentOrganizationId : {
                matchMode : 'equals',
                value: 0,
                text: ''
              }
            },
            
            fields : [
              {
                key : 'form',
                label: this.$t('form')
              },
              {
                key : 'normativeLegalDoc',
                label: this.$t('normativeLegalDoc')
              },
              {
                key : 'okeds',
                label: this.$t('oked')
              },
              {
                key : 'parentOrganization',
                label: this.$t('parentOrganization')
              }
            ]
        }
    },
    created(){
        this.Refresh()
        this.getOrganizationList()
        this.lang = localStorage.getItem('locale') || 'uz_cyrl'
    },
    methods:{
        Refresh(){
          // if(!!this.filter.certificateNumber){
            this.SearchLoading = true
            if(!!this.filters.parentOrganizationId.value){
              this.filter.filters = this.filters
            }
            ControlFunctionService.GetList(this.filter).then(res => {
                this.Parent = res.data.rows
                this.SearchLoading = false
            }).catch(error => {
                this.SearchLoading = false
                this.makeToast(error.response.data.errors,'error')
            })
          // }
        },
        getOrganizationList(){
          ManualService.GetOrganizationAsSelectList(this.filter.parentId, this.filter.authorizedOnly, this.filter.inspectionOnly).then(res => {
            this.OrganizationList = res.data
          })
        },
        makeToast(message,type){
            var a = ""
            if(message.status == 500){
                a = message.title
            }
            if(message.status == 400){
                var errors = Object.values(message.errors)
                var a = errors.map((el,item) => (item + 1) + "."  + el ).join('\n')
            }
            else{
              a = message
            }
            this.$toast.open({
                message: a,
                type: type,
                duration: 5000,
                dismissible: true
            })
        },

    }
}
</script>

<style>

</style>