import ApiService from './api.service'
const ControlFunctionService = {
  GetList(data) {
    return ApiService.post(`/ControlFunction/GetList`, data);
  },
  GetListLandingPage(data) {
    return ApiService.post(`/ControlFunction/GetListLandingPage`, data);
  },
  GetAsSelectList(parentOrganizationId) {
    return ApiService.get(
      `/ControlFunction/GetAsSelectList?parentOrganizationId=${parentOrganizationId}`
    );
  },
  GetSecurityInfo() {
    return ApiService.print(`/ControlFunction/GetSecurityInfo`);
  },
};
export default ControlFunctionService